<template>
  <div class="curriculum"  v-touch:swipe.left="onSwipeLeft" v-touch:swipe.right="onSwipeRight">
      <!-- <div class="level l1" @click="gotoPage('L1')"></div>
      <div class="level l2" @click="gotoPage('L2')"></div>
      <div class="level l3" @click="gotoPage('L3')"></div> -->
      <img src="https://alicdn.joyballet.cn/studentStudy/capability/upgrade/curriculum.png"
      ondragstart="return false;" alt=""
      v-if="user.level_name=='L1'
      ||user.level_name=='L2'||user.level_name=='L3'"
       @click="gotoPage(user.level_name)">
      <img src="https://alicdn.joyballet.cn/studentStudy/capability/upgrade/curriculum.png" v-else
      ondragstart="return false;" alt="">
      <div class="to" @click="onSwipeLeft">
          <img src="../assets/img/home/right.png" alt="">
      </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as d3 from 'd3';
export default {
  data() {
    return {
      title: '',
      user: '',
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('student'));
    console.log(this.user);
  },
  mounted() {
    // js
  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
    onSwipeLeft() {
      this.$router.push({
        name: 'Exhibit',
      });
    },
    gotoPage(level) {
      this.$router.push({
        name: 'Reward',
        params: {
          level,
        },
      });
    },
  },
};
</script>
<style lang="less">
.curriculum{
    width: 100vw;
    min-height: 100vh;
    position: relative;
    background: #fffdfa;
    img{
        width: 100%;
    }
    .level{
        position: absolute;
        width: 8vw;

    }
    .l1{
        left: 13vw;
        top: 24.8vw;
        height: 44vw;
    }
    .l2{
        left: 21.2vw;
        top: 24.8vw;
        height: 44vw;
    }
    .l3{
        left: 29.3vw;
        top: 23.2vw;
        height: 45.4vw;
    }
    .to{
      height: 1rem;
      align-items: flex-end;
      bottom: 0.1rem;
    }
}
</style>
